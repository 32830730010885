import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";

const CardImage = styled.div`
  max-width: 160px;
  min-width: 160px;
  min-height: 160px;
  max-height: 160px;
  overflow: hidden;
  border-radius: 500px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 29px;
`;

const ImageContainer = styled.div`
  width: 100%;
`;

const TeamCard = ({ image, title, children, ...rest }) => (
  <Box
    className="text-center"
    pt="15px"
    px="30px"
    borderRadius={10}
    mb={4}
    {...rest}
  >
    <CardImage>
      <ImageContainer>
        <PreviewCompatibleImage
          imageInfo={{
            image: image,
          }}
        />
      </ImageContainer>
    </CardImage>
    <div className="text-center">
      <Title variant="card" fontSize="24px" letterSpacing={-0.75} my={1}>
        {title}
      </Title>
      <Text fontSize={2} lineHeight={1.75}>
        {children}
      </Text>
    </div>
  </Box>
);

const Team = (props) => (
  <>
    {/* <!-- Team section --> */}
    <Section className="position-relative pt-5">
      <Container>
        <Row className="justify-content-center">
          <Col lg="6" className="text-center pb-3">
            <div className="">
              <Title>{props.team}</Title>
              <Text>{props.teamdescription}</Text>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          {props.members.map((member, i) => (
            <Col
              key={i}
              sm="6"
              lg={props.members.length < 4 ? "4" : "3"}
              className="mt-3 mt-lg-4"
            >
              <TeamCard image={member.image} title={member.name}>
                {member.role}
              </TeamCard>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  </>
);

export default Team;
